import React from "react"

import Page from "../components/Page";

import * as style from "./index.module.scss";


export default ({ location }) => {
    return (
        <Page location={location}>
            <div className={style.root} >

            </div>
        </Page>
    );
}

